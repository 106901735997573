import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useStorage } from '@vueuse/core'
import { SteelterPlans } from '../types'

export const useUserSession = defineStore('userSession', () => {
  // token will be synced with local storage
  // @see https://vueuse.org/core/usestorage/
  const token = useStorage('token', '')

  const tenantId = useStorage('tenantId', '')
  const currentPlan = useStorage('currentPlan', '')
  const loading = ref(true)
  const isB2B2BSession = ref(false)

  const defaultLocale = useStorage(
    'locale',
    /* ['ca', 'es', 'en', 'fr', 'pt', 'it', 'de'].includes(navigator?.language.slice(0, 2)) */
    ['es', 'en'].includes(navigator?.language.slice(0, 2))
      ? navigator?.language.slice(0, 2)
      : 'es'
  )
  const isLoggedIn = computed(() => token.value !== undefined && token.value !== '')

  const company = useStorage('company', {
    id: '',
    name: '',
    logo: '',
    organizationUnits: [],
  })

  const user = useStorage('user', {
    id: '',
    username: '',
    email: '',
    consultingName: '',
    language: defaultLocale.value,
    roleUser: '',
    mfa: false,
    isB2B2BSession: false,
    termsAccepted: false,
    termsAcceptedOn: null,
    dataRestrictions: false,
  })

  function setUser(newUser: any) {
    user.value = newUser
  }

  function setToken(newToken: string) {
    token.value = newToken
  }

  function setTenant(newTenant: string) {
    tenantId.value = newTenant
  }

  function setCurrentPlan(plan: SteelterPlans) {
    currentPlan.value = plan
  }

  function setCompany(newCompany: any) {
    company.value = newCompany
  }

  function setIsB2B2BSession(isB2B2B: boolean) {
    isB2B2BSession.value = isB2B2B
  }

  function setLoading(newLoading: boolean) {
    loading.value = newLoading
  }

  function companyHasOrganizationUnit(unitName: string): boolean {
    const filter = company.value.organizationUnits.filter(function (unit: Object) {
      return unit?.name === unitName
    })

    return filter.length > 0
  }

  async function logoutUser() {
    token.value = undefined
    tenantId.value = undefined
    currentPlan.value = undefined
    company.value = undefined
    user.value = undefined
    isB2B2BSession.value = false
  }

  return {
    user,
    token,
    tenantId,
    currentPlan,
    company,
    isLoggedIn,
    loading,
    isB2B2BSession,
    logoutUser,
    setUser,
    setToken,
    setTenant,
    setCurrentPlan,
    setCompany,
    companyHasOrganizationUnit,
    setLoading,
    setIsB2B2BSession,
  } as const
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserSession, import.meta.hot))
}
